import OtpInput from "../Components/OtpInput"
import { useState,useEffect } from "react";
import { BACKEND_API_URL } from "../constants";
import Hashids from "hashids";



const Verify = () => {
    const [otp, setOtp] = useState("");
    const [err, setErr] = useState("")
    const [isSubmitted, setIsSubmitted] = useState(false)
    const hashids = new Hashids("labrador.ai")
    const [logo , setLogo] = useState()

    const pathParameter = window.location.pathname.split('/')[2]
    const [phone,campaign_id,store_id] = hashids.decode(pathParameter)
    const hash = hashids.encode(phone)  

    const getLogo = (storeId) =>{
        fetch(`${BACKEND_API_URL}campaign/${store_id}/getlogo/?store_id=${storeId}`).then(async(resp)=>await resp.json()).then(resp => setLogo(resp.logo_file))
    }

    useEffect(()=>getLogo(store_id))

    const verifyOtp = () => {
        const url = `${BACKEND_API_URL}campaign/${store_id}/verifyotp/?phone=${phone}&campaign=${campaign_id}&store_id=${store_id}`
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'otp': otp ,'pass':false}),
        }
        fetch(url, options).then(async (resp) => { return { status: resp.status, body: await resp.json() } }).then((resp) => {
            console.log(resp)
            if (resp.status === 200) {
                localStorage.setItem('user',hash)
                const stores = JSON.parse(localStorage.getItem('stores')) || []
                if (!stores.includes(store_id.toString())){
                    localStorage.setItem('stores',JSON.stringify([...stores,store_id]))
                }
                window.location.href = resp.body.redirect_url
            } else if (resp.status === 403) {
                setErr("Invalid PIN")
                setIsSubmitted(false)
            } else {
                setErr("Something went wrong. Please try again")
                setIsSubmitted(false)
            }
        })
    }

    const handleSubmit = () => {
        if (otp.toString().length < 6) {
            setErr("Please enter the PIN")
        }
        else {
            setErr("")
            setIsSubmitted(true)
            verifyOtp(otp)
        }
    }
    return <div className="container">
        <img style={{maxWidth:"300px"}} src={logo}></img>
        <p>Enter PIN to unlock your message </p>
        <OtpInput
            value={otp}
            onChange={(val) => {
                setOtp(val);
            }}
        />
        {err && <p className="error">{err}</p>}
        <button disabled={isSubmitted} className={isSubmitted ? "disabled main-button" : "main-button"} onClick={() => handleSubmit()} >Submit</button>
        <p class="terms">You will not be asked to verify again unless your device changes </p>
    </div>
}

export default Verify