import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Verify from './pages/Verify';
import Landing from './pages/Landing';
import NotFound from './pages/NotFound';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
              <Route index path=":hash" element={<Landing />} />
              <Route path="verify/:hash" element={<Verify />}/>
              <Route path="*" element={<NotFound />}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
