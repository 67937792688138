import {  useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";
import { BACKEND_API_URL } from "../constants"
import Hashids from "hashids";


const Landing = () => {

    const [err, setErr] = useState("")
    const navigate = useNavigate();
    const hashids = new Hashids("labrador.ai")
    const pathParameter = window.location.pathname.split('/')[1]
    const [phone,campaign_id,store_id] = hashids.decode(pathParameter)
    const [logo , setLogo] = useState()


    const hash = hashids.encode(phone)    
    const redirectUser = () => {
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'otp': 0,'pass':true }),

        }
        const url = `${BACKEND_API_URL}campaign/${store_id}/verifyotp/?phone=${phone}&campaign=${campaign_id}&store_id=${store_id}&pass=true`
        fetch(url, options).then(async (resp) => { return { status: resp.status, body: await resp.json() } }).then((resp) => {
            console.log(resp)
            if (resp.status === 200) {
                window.location.href = resp.body.redirect_url
            }  
        })
    }

    const getLogo = (storeId) =>{
        fetch(`${BACKEND_API_URL}campaign/${store_id}/getlogo/?store_id=${storeId}`).then(async(resp)=>await resp.json()).then(resp => setLogo(resp.logo_file))
    }

    useEffect(()=>{
        const browserHash = localStorage.getItem('user')
        const stores = JSON.parse(localStorage.getItem('stores')) || []
        getLogo(store_id)
        if (browserHash == hash && stores.includes(store_id) ){
            redirectUser()
        }
    })

    const handleSubmit = () => {
        if (hash && campaign_id && store_id) {
            const url = `${BACKEND_API_URL}campaign/${store_id}/getotp/?phone=${phone}&campaign=${campaign_id}&store_id=${store_id}`
            fetch(url).then((resp) => {
                if (resp.status === 200) {
                    navigate(`/verify/${pathParameter}`)
                }
                else {
                    setErr("Something went wrong ! Please check the URL")
                }
            })
        } else {
            setErr("Something went wrong ! Please check the URL")
        }
    }
    return <div className="container">
        <img style={{ maxWidth:"300px"}} src={logo}></img>
        <p>We need to text you a one time PIN to verify your account </p>
        <button onClick={() => handleSubmit()} className="main-button">Send verification PIN</button>
        {err && <p className="error">{err}</p>}
    </div>
}

export default Landing