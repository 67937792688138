import React from "react";

const OtpInput = (props) => {
    const {
        size = 6,
        validationPattern = /[0-9]{1}/,
        value,
        onChange,
        className,
        ...restProps
    } = props;
    const handleInputChange = (
        e,
        index
    ) => {
        const elem = e.target;
        const val = e.target.value;
        if (!validationPattern.test(val) && val !== "") return;

        const valueArr = value.split("");
        valueArr[index] = val;
        const newVal = valueArr.join("").slice(0, 6);
        onChange(newVal);

        if (val) {
            const next = elem.nextElementSibling
            next?.focus();
        }
    };

    const arr = new Array(size).fill("-");
    return (
        <div className="code-container">
            {arr.map((_, index) => {
                return (
                    <input
                        className="code"
                        key={index}
                        {...restProps}
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        pattern={validationPattern.source}
                        maxLength={6}
                        value={value.at(index) ?? ""}
                        onChange={(e) => handleInputChange(e, index)}
                    />
                );
            })}
        </div>
    );
};

export default OtpInput;